export enum IdDocImageProcessingError {
  docTypeMismatch = 'doc_type_mismatch',
  unknownDocumentType = 'unknown_document_type',
  unsupportedDocumentType = 'unsupported_document_type',
  wrongDocumentSide = 'wrong_document_side',
  wrongOneSidedDocument = 'wrong_one_sided_document',
  documentNotReadable = 'document_not_readable',
  documentGlare = 'document_glare',
  documentSharpness = 'document_sharpness',
  unableToAlignDocument = 'unable_to_align_document',
  idTypeNotAcceptable = 'id_type_not_acceptable',
  unknownCountryCode = 'unknown_country_code',
  countryCodeMismatch = 'country_code_mismatch',
  selfieFaceNotFound = 'selfie_face_not_found',
  selfieLowConfidence = 'selfie_low_confidence',
  selfieTooDark = 'selfie_too_dark',
  selfieGlare = 'selfie_glare',
  selfieHasLenses = 'selfie_has_lenses',
  selfieHasFaceMask = 'selfie_has_face_mask',
  selfieBlurry = 'selfie_blurry',
  selfieImageSizeUnsupported = 'selfie_image_size_unsupported',
  selfieImageOrientationIncorrect = 'selfie_image_orientation_incorrect',
  selfieBadImageCompression = 'selfie_bad_image_compression',
  driversLicensePermitNotAllowed = 'drivers_license_permit_not_allowed',
  unknownError = 'unknown_error',
  networkError = 'network_error',
  faceNotFound = 'face_not_found',
  militaryIdNotAllowed = 'military_id_not_allowed',
}

export enum IdDocImageUploadError {
  unknownUploadError = 'unknown_upload_error',
  multipleFilesError = 'multiple_files_error',
  fileTypeNotAllowed = 'file_type_not_allowed',
  heicNotSupported = 'heic_not_supported',
}
