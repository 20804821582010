import type { InsightEvent } from './insight-event';

export enum IdentifyScope {
  my1fp = 'my1fp',
  onboarding = 'onboarding',
  auth = 'auth',
}

export type AuthEvent = {
  insight?: InsightEvent;
  kind: AuthEventKind;
  scope: IdentifyScope;
};

export enum AuthEventKind {
  sms = 'sms',
  passkey = 'passkey',
  email = 'email',
  synthetic = 'synthetic',
}
