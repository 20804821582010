import { cva } from 'class-variance-authority';
import Button from '../../button';
import LinkButton from '../../link-button';
import type { DialogFooter } from '../dialog.types';

const footerVariants = cva(
  'flex flex-col flex-0 box-border md:flex-row-reverse items-center justify-between w-full py-3 md:py-4 md:px-6 px-3 bg-background-primary relative',
  {
    variants: {
      size: {
        'full-screen': '',
        default: '',
      },
    },
  },
);

const buttonContainerVariants = cva('flex gap-2 w-full', {
  variants: {
    layout: {
      'full-screen': 'flex-row justify-between',
      'full-screen-single-button': 'flex-row justify-end',
      'full-screen-with-link': 'flex-row justify-end',
      default: 'flex-col-reverse md:flex-row md:justify-end',
    },
  },
});

const Footer = ({ linkButton, primaryButton, secondaryButton, size, hasScroll }: DialogFooter) => {
  const hasBorder = size === 'full-screen' || hasScroll;
  const isFullScreen = size === 'full-screen';

  const getLayout = () => {
    if (!isFullScreen) return 'default';

    if (primaryButton && secondaryButton && !linkButton) {
      return 'full-screen';
    }
    if (linkButton) {
      return 'full-screen-with-link';
    }
    return 'full-screen-single-button';
  };

  return (
    <footer
      className={`${footerVariants({ size: isFullScreen ? 'full-screen' : 'default' })}`}
      style={
        {
          borderColor: hasBorder ? 'var(--border-color-tertiary)' : 'transparent',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderPosition: 'top',
        } as React.CSSProperties
      }
    >
      <div className="flex w-full items-center">
        {isFullScreen && linkButton && (
          <div className="flex">
            <LinkButton onClick={linkButton.onClick} type={linkButton.type} form={linkButton.form}>
              {linkButton.label}
            </LinkButton>
          </div>
        )}

        <div className={buttonContainerVariants({ layout: getLayout() })}>
          {secondaryButton && (
            <Button
              id={secondaryButton.id}
              disabled={secondaryButton.disabled}
              form={secondaryButton.form}
              loading={secondaryButton.loading}
              loadingAriaLabel={secondaryButton.loadingAriaLabel}
              onClick={secondaryButton.onClick}
              type={secondaryButton.type}
              variant="secondary"
            >
              {secondaryButton.label}
            </Button>
          )}
          {primaryButton && (
            <Button
              id={primaryButton.id}
              disabled={primaryButton.disabled}
              form={primaryButton.form}
              loading={primaryButton.loading}
              loadingAriaLabel={primaryButton.loadingAriaLabel}
              onClick={primaryButton.onClick}
              type={primaryButton.type}
              variant="primary"
            >
              {primaryButton.label}
            </Button>
          )}
        </div>
      </div>

      {!isFullScreen && linkButton && (
        <div className="flex p-3 md:p-0">
          <LinkButton onClick={linkButton.onClick} type={linkButton.type} form={linkButton.form}>
            {linkButton.label}
          </LinkButton>
        </div>
      )}
    </footer>
  );
};

export default Footer;
