import type { VaultValue } from '@onefootprint/core';
import type { DataIdentifier } from './di';
import type { EntityBankAccount } from './entity-bank-account';
import type { EntityCard } from './entity-cards';
import type { InsightEvent } from './insight-event';
import type { Tag } from './tag';
import type { WatchlistCheckEventData } from './timeline';
import type { DataKind } from './vault';

export type EntityVault = Partial<Record<DataIdentifier, VaultValue>> & {
  cards?: EntityCard[];
  bankAccounts?: EntityBankAccount[];
};

export enum EntityKind {
  business = 'business',
  person = 'person',
}

export enum EntityLabel {
  active = 'active',
  offboard_fraud = 'offboard_fraud',
  offboard_other = 'offboard_other',
}

export enum EntityStatus {
  pass = 'pass',
  failed = 'fail',
  incomplete = 'incomplete',
  inProgress = 'in_progress',
  pending = 'pending',
  none = 'none',
}

export type Transforms = {
  prefix_1?: string;
};

export type Attribute = {
  identifier: DataIdentifier;
  source: string;
  isDecryptable: boolean;
  dataKind: DataKind;
  value: VaultValue;
  transforms: Transforms;
};

export type Entity = {
  svId?: string;
  vId?: string;
  data: Attribute[];
  tags?: Tag[];
  hasOutstandingWorkflowRequest: boolean;
  id: string;
  isIdentifiable: boolean;
  kind: EntityKind;
  label: EntityLabel | null;
  lastActivityAt: string;
  requiresManualReview: boolean;
  sandboxId?: string;
  externalId?: string;
  startTimestamp: string;
  status: EntityStatus;
  watchlistCheck: WatchlistCheckEventData | null;
  workflows: EntityWorkflow[];
};

export enum WorkflowStatus {
  pass = 'pass',
  fail = 'fail',
  incomplete = 'incomplete',
  pending = 'pending',
  none = 'none',
}

export type EntityWorkflow = {
  createdAt: string;
  playbookId: string;
  insightEvent?: InsightEvent;
  status?: WorkflowStatus;
};
