import type { Theme } from '@onefootprint/design-tokens';
import type React from 'react';
import { ThemeProvider } from 'styled-components';
import ToastProvider from '../../components/toast/toast-provider';
import ConfirmationDialogProvider from '../confirmation-dialog-provider';

export type BootstrapProps = {
  children: React.ReactNode;
  theme: Theme;
};

const DesignSystemProvider = ({ children, theme }: BootstrapProps) => (
  <ThemeProvider theme={theme}>
    <ConfirmationDialogProvider>
      <ToastProvider>{children}</ToastProvider>
    </ConfirmationDialogProvider>
    <div id="footprint-portal" />
    <div id="footprint-toast-portal" />
    {process.env.NODE_ENV === 'test' ? null : (
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/rafaelmotta/footprint-flags/sprite.css" />
    )}
  </ThemeProvider>
);

export default DesignSystemProvider;
